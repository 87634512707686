import { FaInstagram } from "react-icons/fa6";
import { LiaTelegramPlane } from "react-icons/lia";
import { LuFacebook } from "react-icons/lu";
import { MdOutlineWhatsapp } from "react-icons/md";
import { SlSocialLinkedin } from "react-icons/sl";
import { FaRegFile } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLocationOn } from "react-icons/md";

export const cardBannerData = {
  subTitle: "card_subTitle",
  title: "card_title",
  text: "card_text",
  find: "find_me",
  image:
    "https://rainbowit.net/themes/inbio/wp-content/uploads/2021/08/banner-01.png",
};

export const dataSocial = [
  {
    href: "https://www.facebook.com/profile.php?id=100084702367733&mibextid=2JQ9oc",
    icon: <LuFacebook />,
  },
  {
    href: "https://www.instagram.com/gevor_abrahamayn/",
    icon: <FaInstagram />,
  },
  {
    href: "https://www.linkedin.com/in/gevorg-abrahamyan-0b4541200/",
    icon: <SlSocialLinkedin />,
  },
  {
    href: "https://t.me/gevor_abrahamyan",
    icon: <LiaTelegramPlane />,
  },
  {
    href: "https://wa.me/37496222209",
    icon: <MdOutlineWhatsapp />,
  },
];

export const loopData = [
  {
    icon: <FaRegFile />,
    text: "FullStack Web Developer",
  },
  {
    icon: <HiOutlineMail />,
    text: "info@gevorgabrahamyan.com",
  },
  {
    icon: <MdOutlineLocationOn />,
    text: "Armenia, Yerevan",
  },
];
