import Service from "./Service";
import StandartContainer from "./StandartContainer";
import { serviceData } from "../../data/serviceData";
import { useParams } from "react-router-dom";

export default function SingleService() {
  const { id } = useParams();
  const singleData = serviceData.find((item) => item.id == id);

  const content = (
    <>
      <Service direction={"service"} singleData={singleData.singlData} />
    </>
  );
  return <StandartContainer content={content} />;
}
