import { Link } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import Border from "./Border";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import { Context } from "../Multilanguage/wrapper";

export default function Portfolio({ location, data, singleData }) {
  const { locale } = useContext(Context);

  return (
    <>
      {location == "home" ? (
        <>
          <div className="flex flex-col gap-6 lg:flex-row">
            {data &&
              data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="p-6 cursor-pointer group lg:w-1/3 bg-shadow rounded-xl"
                  >
                    <div className="overflow-hidden">
                      <img
                        src={item.image}
                        className="w-full transition-all duration-300 group-hover:scale-110"
                        alt=""
                      />
                    </div>
                    <div className="flex items-end h-8 gap-4 font-logo-text">
                      <Link
                        to={`/portfolio-single/${item.title
                          .split(" ")
                          .join("-")}`}
                        className="text-lg leading-none"
                      >
                        {item.title}
                      </Link>
                      <BsArrowUpRight className="text-[0px] group-hover:text-[20px] transition-all duration-300" />
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <div className="p-6 bg-shadow">
          <div className="flex flex-col lg:flex-row">
            <div className="overflow-hidden cursor-pointer lg:w-1/2 group">
              <img
                src={singleData[0].image}
                className="object-cover w-full h-full transition-all duration-300 group-hover:scale-110"
                alt="Service Text"
              />
            </div>
            <div className="flex flex-col justify-between lg:px-6 lg:w-1/2">
              <div>
                <div
                  className={`${
                    locale === "am"
                      ? "font-armenian-font text-xl"
                      : "font-logo-text text-2xl"
                  } pt-6 lg:pt-0  text-themeDark dark:text-themeLight duration-300 transition-all`}
                >
                  {singleData[0].title}
                </div>
                <div
                  className={`${
                    locale === "am"
                      ? "font-armenian-other text-sm"
                      : "font-other-text text-md"
                  } mt-8  capitalize text-justify opacity-70 transition-all duration-300`}
                >
                  {singleData[0].text[locale]}
                </div>
              </div>
              <div className={`flex flex-col lg:flex-row gap-6 mt-8`}>
                <Link
                  to={singleData[0].projectLink}
                  target="_blank"
                  className={`${
                    locale === "am"
                      ? "font-armenian-font text-md"
                      : "font-other-text text-lg"
                  } relative tracking-widest cursor-pointer transition-all duration-300 hover:-translate-y-2 capitalize px-8 py-4 bg-shadow `}
                >
                  {singleData[0].linkText[locale]}
                </Link>
                <Link
                  to={"/skills"}
                  className={`${
                    locale === "am"
                      ? "font-armenian-font text-md"
                      : "font-other-text text-lg"
                  } relative tracking-widest capitalize px-8 py-4 bg-shadow cursor-pointer transition-all duration-300 hover:-translate-y-2`}
                >
                  <FormattedMessage id="navbar_skills" />
                </Link>
                <Link
                  to={"/projects"}
                  className={`${
                    locale === "am"
                      ? "font-armenian-font text-md"
                      : "font-other-text text-lg"
                  } relative tracking-widest cursor-pointer transition-all duration-300 hover:-translate-y-2 capitalize px-8 py-4 bg-shadow `}
                >
                  <FormattedMessage id="navbar_projects" />
                </Link>
              </div>
            </div>
          </div>
          <Border />
          <div
            className={`${
              locale === "am"
                ? "font-armenian-other text-sm"
                : "font-other-text text-md"
            } mt-8  capitalize text-justify opacity-70 transition-all duration-300 font-normal`}
          >
            {singleData[0].aboutSmall[locale]}
          </div>
          <Border />
          <div className="flex flex-col gap-8">
            {singleData[0].serviceDescription &&
              singleData[0].serviceDescription.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className={`${
                        locale === "am"
                          ? "font-armenian-font text-xl"
                          : "font-logo-text text-2xl"
                      } capitalize text-themeDark dark:text-themeLight duration-300 transition-all`}
                    >
                      {item.title}
                    </div>
                    <div
                      className={`${
                        locale === "am"
                          ? "font-armenian-other text-sm"
                          : "font-other-text text-md"
                      } mt-8  capitalize text-justify opacity-70 transition-all duration-300 font-normal`}
                    >
                      {item.text[locale]}
                    </div>
                  </div>
                );
              })}
          </div>
          <Border />
          <div className="flex flex-col gap-8 md:flex-row">
            {singleData[0].galleryImages &&
              singleData[0].galleryImages.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="overflow-hidden md:w-1/3 rounded-xl bg-shadow"
                  >
                    <img src={item.src} alt={item.alt} />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
