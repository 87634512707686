import { cardBannerData, dataSocial, loopData } from "../data/cardBannerData";
import { serviceData, servicePageData } from "../data/serviceData";
import { portfolio, portfolioData } from "../data/portfolio";
import { FormattedMessage } from "react-intl";

import Border from "./Components/Border";
import CardBanner from "./Components/CardBanner";
import Image from "../assets/images/001.jpg";
import StandartContainer from "./Components/StandartContainer";
import Service from "./Components/Service";
import SectionTitle from "./Components/SectionTitle";
import Portfolio from "./Components/Portfolio";

export default function Home() {
  const content = (
    <>
      <CardBanner
        image={Image}
        subTitle={<FormattedMessage id={cardBannerData.subTitle} />}
        title={<FormattedMessage id={cardBannerData.title} />}
        text={<FormattedMessage id={cardBannerData.text} />}
        find={<FormattedMessage id={cardBannerData.find} />}
        data={loopData}
        dataSocial={dataSocial}
      />
      <Border />
      <SectionTitle
        smallTitle={servicePageData[0].smallTitle}
        title={servicePageData[0].title}
      />
      <Service direction={"home"} data={serviceData} />
      <Border />
      <SectionTitle
        smallTitle={portfolioData[0].smallTitle}
        title={portfolioData[0].title}
      />
      <Portfolio data={portfolio} location={"home"} />
      <Border />
    </>
  );

  return <StandartContainer content={content} />;
}
