import { Context } from "../Multilanguage/wrapper";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useContext } from "react";
import Border from "./Border";

export default function Service({ direction, data, singleData }) {
  const { locale } = useContext(Context);

  return (
    <>
      {direction == "home" ? (
        <>
          <div className="flex flex-col gap-6 lg:flex-row">
            {data &&
              data.map((item, index) => {
                return (
                  <div
                    className="p-6 cursor-pointer lg:w-1/3 bg-shadow rounded-xl group"
                    key={index}
                  >
                    <div className="transition-all duration-300 group-hover:-translate-y-4">
                      <div className="text-5xl">{item.icon}</div>
                      <div
                        className={`${
                          locale === "am"
                            ? "font-armenian-font text-xl"
                            : "font-logo-text text-2xl"
                        } mt-8  capitalize`}
                      >
                        {item.title[locale]}
                      </div>
                      <div
                        className={`${
                          locale === "am" ? "text-sm " : "text-md"
                        } mt-6 font-normal text-justify`}
                      >
                        {item.text[locale]}
                      </div>
                      <div className="mt-6 text-3xl transition-all duration-300 opacity-0 group-hover:opacity-100">
                        <Link
                          to={`/service-single/${item.id}`}
                          className="animate-pulse"
                        >
                          <FaLongArrowAltRight />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <div className="p-6 bg-shadow">
          <div className="flex flex-col md:flex-row">
            <div className="overflow-hidden cursor-pointer md:w-1/2 group">
              <img
                src={singleData[0].image}
                className="object-cover w-full h-full transition-all duration-300 group-hover:scale-110"
                alt="Service Text"
              />
            </div>
            <div className="flex flex-col justify-between md:px-6 md:w-1/2">
              <div>
                <div
                  className={`${
                    locale === "am"
                      ? "font-armenian-font text-xl"
                      : "font-logo-text text-2xl"
                  } pt-6 md:pt-0  text-themeDark dark:text-themeLight duration-300 transition-all`}
                >
                  {singleData[0].title[locale]}
                </div>
                <div
                  className={`${
                    locale === "am"
                      ? "font-armenian-other text-sm"
                      : "font-other-text text-md"
                  } mt-8  capitalize text-justify opacity-70 transition-all duration-300`}
                >
                  {singleData[0].text[locale]}
                </div>
              </div>
              <div className={`flex gap-6 mt-8`}>
                <Link
                  to={"/projects"}
                  className={`${
                    locale === "am"
                      ? "font-armenian-font text-md"
                      : "font-other-text text-lg"
                  } relative tracking-widest cursor-pointer transition-all duration-300 hover:-translate-y-2 capitalize px-8 py-4 bg-shadow `}
                >
                  <FormattedMessage id="navbar_projects" />
                </Link>
                <Link
                  to={"/skills"}
                  className={`${
                    locale === "am"
                      ? "font-armenian-font text-md"
                      : "font-other-text text-lg"
                  } relative tracking-widest capitalize px-8 py-4 bg-shadow cursor-pointer transition-all duration-300 hover:-translate-y-2`}
                >
                  <FormattedMessage id="navbar_skills" />
                </Link>
              </div>
            </div>
          </div>
          <Border />
          <div
            className={`${
              locale === "am"
                ? "font-armenian-other text-sm"
                : "font-other-text text-md"
            } mt-8  capitalize text-justify opacity-70 transition-all duration-300 font-normal`}
          >
            {singleData[0].aboutSmall[locale]}
          </div>
          <Border />
          <div className="flex flex-col gap-8">
            {singleData[0].serviceDescription &&
              singleData[0].serviceDescription.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className={`${
                        locale === "am"
                          ? "font-armenian-font text-xl"
                          : "font-logo-text text-2xl"
                      } capitalize text-themeDark dark:text-themeLight duration-300 transition-all`}
                    >
                      {item.title[locale]}
                    </div>
                    <div
                      className={`${
                        locale === "am"
                          ? "font-armenian-other text-sm"
                          : "font-other-text text-md"
                      } mt-8  capitalize text-justify opacity-70 transition-all duration-300 font-normal`}
                    >
                      {item.text[locale]}
                    </div>
                  </div>
                );
              })}
          </div>
          <Border />
          <div className="flex flex-col gap-8 md:flex-row">
            {singleData[0].galleryImages &&
              singleData[0].galleryImages.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="overflow-hidden md:w-1/3 rounded-xl bg-shadow"
                  >
                    <img src={item.src} alt={item.alt} />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
