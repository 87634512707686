import { useContext } from "react";
import { Context } from "../Multilanguage/wrapper";

export default function SectionTitle({ title, smallTitle }) {
  const { locale } = useContext(Context);
  return (
    <div className="my-12">
      <div>{smallTitle}</div>
      <div
        className={`${
          locale === "am"
            ? "font-armenian-font text-xl"
            : "font-logo-text text-2xl"
        }  text-themeDark dark:text-themeLight duration-300 transition-all`}
      >
        {title[locale]}
      </div>
    </div>
  );
}
