import React, { useContext } from "react";
import { Context } from "../Multilanguage/wrapper";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import StandartContainer from "./StandartContainer";
import ThemeSwitch from "./ThemeSwitch";
import Lang from "../Multilanguage/SetLang";
import { FormattedMessage } from "react-intl";

const navData = [
  {
    text: "navbar_home",
    href: "",
  },
  {
    text: "navbar_about",
    href: "about",
  },
  {
    text: "navbar_projects",
    href: "projects",
  },
  {
    text: "navbar_skills",
    href: "skills",
  },
  {
    text: "navbar_contact",
    href: "contact",
  },
];

function NavList({ locale }) {
  return (
    <ul className="flex flex-col gap-2 my-2 lg:mb-0 lg:mt-0 xl:flex-row xl:items-center lg:gap-6">
      {navData &&
        navData.map((item, index) => {
          return (
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="font-medium "
              key={index}
            >
              <Link
                to={item.href}
                className={`${
                  locale === "am"
                    ? "font-armenian-font text-lg"
                    : "font-logo-text text-xl"
                } flex items-center  font-bold transition-all duration-200 text-bgThemeDark dark:text-bgThemeLight`}
              >
                <FormattedMessage id={item.text} />
              </Link>
            </Typography>
          );
        })}
      <ThemeSwitch />
      <Lang />
    </ul>
  );
}

export default function Header() {
  const [openNav, setOpenNav] = React.useState(false);
  const { locale } = useContext(Context);

  const handleWindowResize = () =>
    window.innerWidth >= 1200 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const content = (
    <div className="container">
      <Navbar
        className="!px-1 py-12  bg-bgThemeLight dark:bg-bgThemeDark"
        id="Navbar"
      >
        <div className="flex items-center justify-between text-blue-gray-900">
          <Link
            to=""
            className="text-xl font-bold transition-all duration-200 font-logo-text text-themeDark dark:text-themeLight"
          >
            Abrahamyan <span className="italic">Gevorg</span>
          </Link>
          <div className="hidden xl:block">
            <NavList locale={locale} />
          </div>
          <IconButton
            variant="text"
            className="w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent xl:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon
                className="w-8 h-8 transition-all duration-200 text-themeDark dark:text-themeLight"
                strokeWidth={2}
              />
            ) : (
              <Bars3Icon
                className="w-8 h-8 transition-all duration-200 text-themeDark dark:text-themeLight"
                strokeWidth={2}
              />
            )}
          </IconButton>
        </div>
        <Collapse open={openNav}>
          <NavList />
        </Collapse>
      </Navbar>
    </div>
  );

  return <StandartContainer content={content} />;
}
