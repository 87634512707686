import { Route, Routes } from "react-router-dom";
import Footer from "./Pages/Components/Footer";
import Header from "./Pages/Components/Header";
import Home from "./Pages/Home";
import ScrollTop from "./Pages/Components/ScrollTop";
import SingleService from "./Pages/Components/SingleService";
import SinglePortfolio from "./Pages/Components/SinglePortfolio";

function App() {
  return (
    <>
      <ScrollTop />
      <Header />

      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/service-single/:id" element={<SingleService />} />
        <Route path="/portfolio-single/:title" element={<SinglePortfolio />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
