import { Context } from "../Multilanguage/wrapper";
import { useContext } from "react";
import { LuUser } from "react-icons/lu";
import { Tooltip, Button } from "@material-tailwind/react";

export default function CardBanner({
  title,
  text,
  image,
  data,
  find,
  dataSocial,
}) {
  const { locale } = useContext(Context);

  return (
    <>
      <div className="flex flex-col gap-8 lg:flex-row">
        <div className="flex flex-col justify-between lg:w-2/5">
          <div className="p-6 cursor-pointer rounded-xl bg-shadow">
            <div className="flex items-center gap-6">
              <div className="flex items-center justify-center w-12 h-12 text-xl rounded-full bg-shadow">
                <LuUser />
              </div>
              <div
                className={`${
                  locale === "am"
                    ? "font-armenian-font text-2xl"
                    : "font-logo-text text-3xl"
                } transition-all duration-300 capitalize`}
              >
                {title}
              </div>
            </div>

            <div
              className={`${
                locale === "am" ? "text-sm " : "text-md"
              } mt-12  font-normal text-justify`}
            >
              {text}
            </div>
            <div className="flex gap-2 mt-12 ">
              {data &&
                data.map((item, index) => {
                  return (
                    <Tooltip content={item.text} key={index}>
                      <Button className="h-16 text-xl hover:bg-transparent bg-shadow">
                        {item.icon}
                      </Button>
                    </Tooltip>
                  );
                })}
            </div>
          </div>
          <div className="px-6 pt-2 pb-4 rounded-lg bg-shadow">
            <div
              className={`${
                locale === "am"
                  ? "font-armenian-font text-md"
                  : "font-logo-text text-lg"
              } capitalize py-1`}
            >
              {find}
            </div>
            <div className="flex gap-6">
              {dataSocial &&
                dataSocial.map((item, index) => {
                  return (
                    <a
                      href={item.href}
                      target="_blank"
                      className="transition-all duration-300 hover:-translate-y-1"
                      key={index}
                    >
                      <div className="p-2 sm:p-4 bg-shadow" key={index}>
                        {item.icon}
                      </div>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="overflow-hidden rounded-lg lg:w-3/5 bg-shadow">
          <img
            src={image}
            className="w-full h-full transition-all duration-300 rounded-lg cursor-pointer hover:scale-110"
          />
        </div>
      </div>
    </>
  );
}
