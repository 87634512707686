import { useParams } from "react-router-dom";
import { portfolio } from "../../data/portfolio";
import StandartContainer from "./StandartContainer";
import Portfolio from "./Portfolio";

export default function SinglePortfolio() {
  const { title } = useParams();
  const formattedTitle = title.split("-").join(" ");

  const filteredPortfolio = portfolio.find(
    (item) => item.title == formattedTitle
  );

  const content = (
    <>
      <Portfolio
        location={"portfolio"}
        singleData={filteredPortfolio.singlData}
      />
    </>
  );

  return <StandartContainer content={content} />;
}
